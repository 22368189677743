import Alpine from 'alpinejs';
import 'preline/dist/overlay';

const app = () => {
  window.Alpine = Alpine;
  Alpine.start();

  // console.log(window.appLocalize);
  // console.log('test');
};

export { app };
